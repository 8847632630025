import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: '',
    intranetUser: '',
    error: {},
    snackBars: [],
    dialogs: []
  },
  mutations: {
    setLayout (state, layout) {
      state.layout = layout
    },
    setUser (state, user) {
      state.intranetUser = user
    },
    setError (state, msg) {
      state.error = msg
    },
    setSnackBar (state, snackbar) {
      state.snackBars = state.snackBars.concat(snackbar)
    },
    addDialog (state, dialog) {
      state.dialogs.push(dialog)
    },
    hideDialog (state, dialogId) {
      const dialog = state.dialogs.find((d) => d.id === dialogId)
      dialog.visible = false
    },
    removeDialog (state, dialogId) {
      state.dialogs.splice(state.dialogs.findIndex((d) => d.id === dialogId), 1)
    }
  },
  actions: {
    setSnackBar ({ commit }, snackbar) {
      snackbar.showing = true
      snackbar.color = snackbar.color || 'success'
      snackbar.text = snackbar.text || 'ok'
      commit('setSnackBar', snackbar)
    }
  },
  modules: {
  }
})
