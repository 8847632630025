import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import api from './plugins/api'
import { AudeDialogPlugin } from '@/plugins/dialog/plugin'

import '@/style/main.scss'
import 'katex/dist/katex.min.css'

Vue.config.productionTip = false

Vue.use(AudeDialogPlugin)

function setLayoutForNextPage (to) {
  let layout = to.matched[0]?.components.default.layout

  if (!layout) {
    layout = 'default'
  }

  store.commit('setLayout', layout)
}

router.afterEach(setLayoutForNextPage)

new Vue({
  router,
  store,
  vuetify,
  api,
  render: h => h(App)
}).$mount('#app')

setLayoutForNextPage(router.currentRoute)
