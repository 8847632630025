<template>
  <v-dialog
    width="500"
    v-bind="$attrs"
    v-on="$listeners"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title>
        Adicionar Mantenedor
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="maintainer.full_name"
          prepend-icon="mdi-account"
          label="Nome completo"
        />
        <v-text-field
          v-model="maintainer.short_name"
          prepend-icon="mdi-account"
          label="Nome de exibição"
        />
        <v-text-field
          v-model="maintainer.email"
          prepend-icon="mdi-email"
          label="E-mail"
        />
      </v-card-text>
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-btn
            color="primary"
            text
            @click="onCancel"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onSubmit"
          >
            Adicionar mantenedor
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    maintainer: {}
  }),

  methods: {
    onCancel () {
      this.$emit('resolve', false)
    },

    onSubmit () {
      // TODO: Validate form
      this.$emit('resolve', this.maintainer)
    }
  }
}
</script>
