import store from '@/store/index.js'

class ConfirmDialogBuilder {
  constructor (title) {
    this.options = {
      title,
      id: Math.random().toString(36).substring(7),
      label: 'OK',
      action: () => {},
      cancelLabel: 'Cancelar',
      cancelAction: () => {}
    }
  }

  label (label) {
    this.options.label = label
    return this
  }

  onConfirm (callback) {
    this.options.action = callback
    return this
  }

  cancelLabel (label) {
    this.options.cancelLabel = label
    return this
  }

  onCancel (callback) {
    this.options.cancelAction = callback
    return this
  }

  text (text) {
    this.options.text = text
    return this
  }

  async show () {
    const value = await new Promise((resolve) => {
      store.commit('addDialog', {
        id: this.options.id,
        type: 'confirm',
        visible: true,
        title: this.options.title,
        text: this.options.text,
        actions: [
          {
            label: this.options.cancelLabel,
            action: () => resolve(false)
          },
          {
            label: this.options.label,
            action: () => resolve(true)
          }
        ]
      })
    })

    if (value) {
      this.options.action()
    } else if (this.options.cancelAction) {
      this.options.cancelAction()
    }

    // Hide dialog and wait for Vuetify transition before removing it
    store.commit('hideDialog', this.options.id)
    await new Promise((resolve) => setTimeout(resolve, 300))
    store.commit('removeDialog', this.options.id)

    return value
  }
}

class CustomDialogBuilder {
  constructor (component) {
    this.id = Math.random().toString(36).substring(7)
    this.component = component
    this.props = {}
  }

  with (props) {
    this.props = props
    return this
  }

  async show () {
    const value = await new Promise((resolve) => {
      store.commit('addDialog', {
        id: this.id,
        type: 'custom',
        visible: true,
        component: this.component,
        props: this.props,
        resolve
      })
    })

    // Hide dialog and wait for Vuetify transition before removing it
    store.commit('hideDialog', this.id)
    await new Promise((resolve) => setTimeout(resolve, 300))
    store.commit('removeDialog', this.id)

    return value
  }
}

const DialogService = {
  confirm (title) {
    return new ConfirmDialogBuilder(title)
  },

  custom (component) {
    return new CustomDialogBuilder(component)
  }
}

export { DialogService }
