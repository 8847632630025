/* eslint-disable */
/* DO NOT EDIT. This file is automatically generated. */
import cargos from '@/pages/cargos/page.vue';

export default [
  {
    path: '/cargos',
    name: 'cargos',
    component: cargos
  }
];
