<template>
  <div>
    <template v-for="dialog in dialogs">
      <v-dialog
        v-if="dialog.type === 'confirm'"
        :key="dialog.id"
        :value="dialog.visible"
        :title="dialog.title"
        persistent
        max-width="400px"
      >
        <v-card>
          <v-card-title>{{ dialog.title }}</v-card-title>
          <v-card-text v-if="dialog.text">
            {{ dialog.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-for="action in dialog.actions"
              :key="action.label"
              color="primary"
              text
              @click="action.action"
            >
              {{ action.label }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <component
        :is="dialog.component"
        v-else-if="dialog.type === 'custom'"
        :key="dialog.id"
        :value="dialog.visible"
        v-bind="dialog.props"
        @resolve="dialog.resolve($event)"
      />
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),

  computed: {
    dialogs () {
      return this.$store.state.dialogs
    }
  }
}
</script>
